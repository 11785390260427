//@import "custom";
@import "~@coreui/coreui/scss/coreui";
@import "~@fortawesome/fontawesome-free/scss/fontawesome.scss";
@import "~@fortawesome/fontawesome-free/scss/regular.scss";
@import "~@fortawesome/fontawesome-free/scss/solid.scss";
//@import "~select2-bootstrap4-theme/src/select2-bootstrap4.scss";
@import "~bootstrap-vue/dist/bootstrap-vue.css";
//@import "~vue-wysiwyg/dist/vueWysiwyg.css";

[v-cloak] {
    display: none;
}

.dropzone {
    padding: 0;
    border: 0;
    min-height: 50px;
    .dz-message {
        padding: 0;
        margin: 0;
    }
}


.dz-preview, .dz-file-preview, .dz-processing, .dz-success, .dz-complete {
    display: none;
}

.v-select {
    display: grid;
}

.v-select .dropdown-toggle {
    @extend .form-control;
}

.v-select input[type=search], .v-select input[type=search] {
    margin: 0 !important;
    border-color: $input-border-color;
}

.v-select .dropdown-toggle {
    border-color: $input-border-color !important;
}

.v-select .dropdown-toggle::after {
    display: none !important;
}

.dashboard-stats-box {

    .stat-icon {
        font-size: 1.75rem;
    }

    .stat-heading {
        font-size: 1rem;
    }

    .stat-stat {
        font-size: 0.75rem;
    }

}

.is-invalid .flatpickr-input {
    border-color: red;
}
