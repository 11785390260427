@import 'bootstrap';
@tailwind base;
@tailwind components;
@tailwind utilities;
body {
    font-family: 'Roboto', sans-serif;
}
* {
    margin: 0px;
    padding: 0px;
    box-sizing: border-box;
    font-family: "Poppins", sans-serif;
}
/*#header {*/
/*    background-image: linear-gradient(rgba(0, 0, 0, 0.0), rgba(0, 0, 0, 0.0)),*/
/*    url("../../public/img/landing-page-bg.png");*/
/*    background-position: center;*/
/*    background-size: cover;*/
/*    width: 100%;*/
/*    height: 75vh;*/
/*    position: relative;*/
/*}*/
:root {
    --main-color: #e6b30e;
}


svg.wave {
    position: absolute;
    bottom: -70px;
}

.middle {
    height: 50vh;
}

.text-darker {
    color: black;
}

.vehicle-inspection-section {
    background: #99ccff;
    background: linear-gradient(180deg,
    #99ccff 0.00%,
    #d6eaff 100.00%);
}


#download-qvin-section {
    background-image: linear-gradient(rgba(0, 0, 0, 0.0), rgba(0, 0, 0, 0.0)),
    url("../../public/img/download-section-bg.png");
    background-position: center;
    background-size: cover;
    width: 100%;
    height: 100%;
    /*position: relative;*/
}

#copyright {
    background-image: linear-gradient(rgba(0, 0, 0, 0.0), rgba(0, 0, 0, 0.0)),
    url("../../public/img/download-section-bg.png");
    background-position: center;
    background-size: cover;
    width: 100%;
    height: 100%;
}

#mobile-header-download {
    background-image: linear-gradient(rgba(0, 0, 0, 0.0), rgba(0, 0, 0, 0.0)),
    url("../../public/img/download-section-bg.png");
    background-position: center;
    background-size: cover;
    width: 100%;
    height: 100%;
}

#footer {
    background: #bbe2ff
}

.custom-vid {
    height: 200px;
    width: 200px;
    position: absolute;
    top: 350px
}

/* Anything Smaller than Large  */
@media (max-width: 767.8px) {
    .bg-qvin-white {
        background-color: #f8f9fa;
    }
}

.no-break {
    white-space: nowrap;
}
